import React, { useState, useEffect } from 'react';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import './App.css';
import {
  Routes,
  Route,
  Navigate,
  useLocation,
} from "react-router-dom";
import LayoutDashboard from './components/LayoutDashboard';
import Account from './components/pages/Users';
import NoPage from './components/pages/NoPage';
import AccountSignIn from './components/pages/account/AccountSignIn';
import Layout from './components/Layout';
import AccountRequestReset from './components/pages/account/accountRequestReset';
import AccountReset from './components/pages/account/accountReset';
import AccountResetStep2 from './components/pages/account/accountResetStep2';
import Settings from './components/pages/Settings';
import { AuthProvider } from './hooks/useAuth';
import { TUserProfile } from './types/types';
import { STORAGE_SHOW_REAL_DATA, STORAGE_USER } from './constants/storage';
import { ProtectedRoute } from './components/auth/ProtectedRoute';
import Sounds from './components/pages/Sounds';
import SoundsEdit from './components/pages/sounds/SoundsEdit';
import Analytics from './components/pages/Analytics';
import AnalyticsSessions from './components/pages/analytics/analyticsSessions';
import AnalyticsUsage from './components/pages/analytics/analyticsUsage';
import AnalyticsUsers from './components/pages/analytics/analyticsUsers';
import AnalyticsMap from './components/pages/analytics/analyticsMap';
import Home from './components/pages/Home';
import HomeReal from './components/pages-real/HomeReal';
import AnalyticsReal from './components/pages-real/AnalyticsReal';
import AnalyticsMapReal from './components/pages-real/analytics/analyticsMapReal';
import AnalyticsSessionsReal from './components/pages-real/analytics/analyticsSessionsReal';
import AnalyticsUsageReal from './components/pages-real/analytics/analyticsUsageReal';
import AnalyticsUsersReal from './components/pages-real/analytics/analyticsUsersReal';

function App() {
  const [user, setUser] = useState<TUserProfile | null>(null);
  const [showRealData, setShowRealData] = useState(window.localStorage.getItem(STORAGE_SHOW_REAL_DATA) === '1');
  const location = useLocation();

  useEffect(() => {
    if (localStorage && localStorage.getItem(STORAGE_USER)) {
      setUser(JSON.parse(localStorage.getItem(STORAGE_USER) || ''));
    }

    setShowRealData(window.localStorage.getItem(STORAGE_SHOW_REAL_DATA) === '1');
  }, [location, location.pathname]);

  return (
    <ThemeProvider theme={firstTheme}>
      <AuthProvider storageValue={user || null}>
        <Routes>
          <Route element={<ProtectedRoute />}>
            <Route element={<LayoutDashboard />}>
              <Route path="/" element={<Navigate to="/dashboard" />} />
              <Route path="dashboard">
                {showRealData ? <>
                  <Route index element={<HomeReal />} />
                  <Route path="analytics" element={<AnalyticsReal />}>
                    <Route path="map" element={<AnalyticsMapReal />} />
                    <Route path="sessions" element={<AnalyticsSessionsReal />} />
                    <Route path="usage" element={<AnalyticsUsageReal />} />
                    <Route path="users" element={<AnalyticsUsersReal />} />
                  </Route>
                </> : <>
                  <Route index element={<Home />} />
                  <Route path="analytics" element={<Analytics />}>
                    <Route path="map" element={<AnalyticsMap />} />
                    <Route path="sessions" element={<AnalyticsSessions />} />
                    <Route path="usage" element={<AnalyticsUsage />} />
                    <Route path="users" element={<AnalyticsUsers />} />
                  </Route>
                </>}
                <Route path="users" element={<Account />} />
                <Route path="sounds" element={<Sounds />} />
                <Route path="sounds/:id" element={<SoundsEdit />} />
                <Route path="settings" element={<Settings />} />
                <Route path="*" element={<NoPage />} />
              </Route>
            </Route>
          </Route>
          <Route element={<Layout />}>
            <Route index element={<AccountSignIn />} />
            <Route path="signin" element={<AccountSignIn />} />
            <Route path="request-reset" element={<AccountRequestReset />} />
            <Route path="reset-password" element={<AccountReset />} />
            <Route path="reset-step2" element={<AccountResetStep2 />} />
          </Route>
          <Route path="*" element={<NoPage />} />
        </Routes>
      </AuthProvider>
    </ThemeProvider>
  );
}

const firstTheme = createTheme({
  typography: {
    fontFamily: 'Poppins, Arial, sans-serif',
    h1: {
      fontSize: '21px',
      fontWeight: '500',
      color: '#333'
    },
    button: {
      fontWeight: 600,
      fontSize: '16px',
      lineHeight: '25.6px',
    },
  },
  palette: {
    primary: {
      main: '#A460F6',
    },
    error: {
      main: '#EB5757',
    }
  },
  components: {
    MuiLink: {
      styleOverrides: {
        root: {
          '&:hover': {
            opacity: 0.6,
          }
        }
      }
    },
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: '30px',
          paddingTop: '6px',
          paddingBottom: '6px',
          lineHeight: '1.25',
          fontWeight: '400',
          textTransform: 'none',
        }
      }
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          color: '#333333',
          fontSize: '16px',
          fontWeight: '400',
          padding: '10px',
          paddingLeft: '20px',
          paddingRight: '20px',
          border: '1px solid #E2E2E2',
          textTransform: 'capitalize',
          backgroundColor: 'white',
          '&.MuiTableCell-head': {
            backgroundColor: 'transparent',
            fontWeight: '500',
          }
        }
      }
    },
    MuiCheckbox: {
      styleOverrides: {
        root: {
          borderRadius: '3px',
          borderColor: '#E2E2E2',
        }
      }
    },
    MuiSelect: {
      styleOverrides: {
        root: {
          overflow: 'hidden',
          borderRadius: '4px',
          backgroundColor: '#fff',
          border: '1px solid',
          borderColor: '#CCCCCC',
          paddingTop: '2px',
          paddingBottom: '2px',
          '& .MuiSelect-select': {
            paddingLeft: '12px',
            paddingTop: '2px',
            paddingBottom: '2px',
          },
          '&:hover': {
            backgroundColor: '#fff',
          },
          '&.Mui-focused': {
            backgroundColor: '#fff',
            borderColor: '#A460F6',
          },
          '& .MuiSelect-select:focus': {
            backgroundColor: '#fff',
          },
          '& .select-icon': {
            width: 10,
            height: 7,
            display: 'flex',
            right: '15px',
            position: 'absolute',
            cursor: 'pointer',
            pointerEvents: 'none',
          }
        }
      }
    },
    MuiTab: {
      styleOverrides: {
        root: {
          '&.MuiTab-root': {
            color: "#999999",
            fontSize: '16px',
            fontWeight: 400,
            padding: '6px 0',
            marginRight: '20px',
            minWidth: '30px',
            minHeight: '30px',
            textTransform: 'none',
          },
          '&.Mui-selected': {
            color: '#000',
            fontWeight: 500,
          },
        },
      }
    },
  }
});

export default App;
