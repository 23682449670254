import { TAnalyticsDataFilters, TAnalyticsUserDataResponse } from "../types/types";
import { handleApiError } from "./handleApiError";
import instance from './index';


export const getUserAnalytics = async (filters: TAnalyticsDataFilters): Promise<TAnalyticsUserDataResponse> => {
  try {
    let params = Object.entries(filters).map(([key, value]) => key + '=' + value.toISOString()).join('&');

    return await instance.get<TAnalyticsUserDataResponse>(`admin/analytics/users${params !== '' ? '?' + params : ''}`).then(res => res.data);
  } catch (error: any) {
    handleApiError(error);
    throw error;
  }
};
