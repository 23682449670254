import {
  Box,
  Typography,
  Link,
  SvgIcon,
} from '@mui/material';
import { useState, useEffect } from 'react';
import { getDashboardAnalytics } from '../../service/dashboardService';


const HomeReal = () => {
  const [show, setShow] = useState(false)
  const [blocksArray, setBlocksArray] = useState([
    {
      icon: <svg width="44" height="45" viewBox="0 0 44 45" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clipPath="url(#clip0_1343_2217)">
          <path d="M34.7699 28.9455C36.1375 26.6573 37.0446 23.7632 37.2206 20.8995C37.3323 19.0615 36.9228 17.1321 36.0359 15.3178C34.6447 12.4677 32.255 10.8666 29.9464 9.31974C28.2235 8.16549 26.5954 7.07554 25.4445 5.51848L25.238 5.24092C24.5577 4.32699 23.7893 3.29121 23.6674 2.42128C23.5456 1.54121 22.7467 0.914996 21.8734 0.965769C20.9866 1.0267 20.2994 1.76122 20.2994 2.65146V32.8111C18.8812 31.9513 17.1345 31.4232 15.2221 31.4232C10.5576 31.4232 6.75977 34.4595 6.75977 38.1931C6.75977 41.9266 10.5576 44.9629 15.2221 44.9629C19.8865 44.9629 23.6843 41.9266 23.6843 38.1931V18.4658C26.2264 19.4373 30.3865 21.9116 31.5306 27.6897C31.3173 28.0045 31.1142 28.343 30.8773 28.6138C30.2579 29.3144 30.3256 30.3841 31.0296 31.0001C31.7269 31.623 32.7965 31.5485 33.416 30.8478C33.8696 30.3333 34.2724 29.7308 34.6582 29.1012C34.6989 29.0538 34.7362 29.003 34.7699 28.9455Z" fill="#A460F6" />
        </g>
        <defs>
          <clipPath id="clip0_1343_2217">
            <rect width="44" height="44" fill="white" transform="translate(0 0.962891)" />
          </clipPath>
        </defs>
      </svg>,
      title: 'Total Sounds',
      count: '0',
      linkLabel: 'Sound Library',
      linkHref: '/dashboard/sounds'
    },
    {
      icon: <svg width="18" height="24" viewBox="0 0 18 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M9 0C4.03737 0 0 3.89916 0 8.69185C0 14.6397 8.05412 23.3716 8.39703 23.7404C8.71912 24.0868 9.28146 24.0862 9.60297 23.7404C9.94588 23.3716 18 14.6397 18 8.69185C17.9999 3.89916 13.9626 0 9 0ZM9 13.065C6.50318 13.065 4.47192 11.1032 4.47192 8.69185C4.47192 6.28051 6.50323 4.31879 9 4.31879C11.4968 4.31879 13.528 6.28055 13.528 8.6919C13.528 11.1032 11.4968 13.065 9 13.065Z" fill="#A460F6" />
      </svg>,
      title: 'Total Countries',
      count: '0',
      linkLabel: 'Map Analytics',
      linkHref: '/dashboard/analytics/map'
    },
    {
      icon: <svg width="46" height="46" viewBox="0 0 46 46" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M29.7098 2H16.2898C13.9215 2.0028 12.0023 4.00127 12 6.46745V39.5329C12.0023 41.9991 13.9215 43.9976 16.2898 44H29.7098C32.0782 43.9976 33.9973 41.9991 34 39.5329V6.46745C33.9973 4.00127 32.0782 2.0028 29.7098 2ZM23 40.182C21.9872 40.182 21.1664 39.3274 21.1664 38.2727C21.1664 37.2184 21.9872 36.3637 23 36.3637C24.0125 36.3637 24.8332 37.2184 24.8332 38.2727C24.8332 39.3274 24.0125 40.182 23 40.182ZM25.75 7.72729H20.25C19.7438 7.72729 19.3332 7.29977 19.3332 6.77262C19.3332 6.24548 19.7438 5.81831 20.25 5.81831H25.75C26.2562 5.81831 26.6664 6.24548 26.6664 6.77262C26.6664 7.29977 26.2562 7.72729 25.75 7.72729Z" fill="#A460F6" />
      </svg>,
      title: 'Total Sessions',
      count: '0',
      linkLabel: 'Sessions Analytics',
      linkHref: '/dashboard/analytics/sessions'
    },
    {
      icon: <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M35.6236 9.93411C35.0241 7.58276 33.7461 5.55524 31.9278 4.07071C30.1566 2.62463 27.9868 1.82812 25.8181 1.82812C24.1553 1.82812 22.5694 2.27582 21.1041 3.15857C19.947 3.85593 18.906 4.80432 17.9969 5.9881C15.929 3.29288 13.1989 1.82812 10.1793 1.82812C8.01142 1.82812 5.84245 2.62463 4.07145 4.07071C2.25376 5.55524 0.976052 7.58249 0.376473 9.93384C-0.0632545 11.6584 -0.26348 14.0787 0.604165 16.9895H6.05256L8.01994 13.0545C8.19874 12.6972 8.56376 12.4714 8.96311 12.4714C9.36274 12.4714 9.72776 12.6972 9.90629 13.0545L13.4812 20.2041L17.0562 13.0545C17.2347 12.6972 17.5997 12.4714 17.9994 12.4714C18.3987 12.4714 18.7637 12.6972 18.9425 13.0545L20.9099 16.9895H28.2557C28.8382 16.9895 29.3101 17.4617 29.3101 18.0439C29.3101 18.6265 28.8382 19.0986 28.2557 19.0986H20.2582C19.8588 19.0986 19.4938 18.8729 19.315 18.5155L17.9994 15.884L14.4244 23.0337C14.2456 23.391 13.8806 23.6165 13.4812 23.6165C13.0816 23.6165 12.7166 23.391 12.5381 23.0337L8.96339 15.884L7.6475 18.5158C7.4687 18.8729 7.10368 19.0986 6.70432 19.0986H1.39408C1.74647 19.8803 2.17713 20.6895 2.70008 21.523C5.57081 26.1013 10.5476 30.3434 17.4915 34.1312C17.6489 34.2172 17.8228 34.26 17.9966 34.26C18.1705 34.26 18.3441 34.2172 18.5014 34.1315C25.4476 30.3431 30.4257 26.1013 33.2978 21.5233C36.4009 16.5767 36.2754 12.4909 35.6236 9.93411Z" fill="#A460F6" />
      </svg>
      ,
      title: 'Avg HRV',
      count: '0',
      linkLabel: 'Usage Analytics',
      linkHref: '/dashboard/analytics/usage'
    },
    {
      icon: <svg width="39" height="39" viewBox="0 0 39 39" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M19.4669 0C8.71674 0 0 8.71503 0 19.466C0 30.217 8.71588 38.932 19.4669 38.932C30.2187 38.932 38.9337 30.217 38.9337 19.466C38.9337 8.71503 30.2187 0 19.4669 0ZM19.4669 5.82056C23.024 5.82056 25.9065 8.70391 25.9065 12.2594C25.9065 15.8156 23.024 18.6981 19.4669 18.6981C15.9114 18.6981 13.0289 15.8156 13.0289 12.2594C13.0289 8.70391 15.9114 5.82056 19.4669 5.82056ZM19.4626 33.8425C15.9148 33.8425 12.6655 32.5505 10.1593 30.4119C9.54873 29.8912 9.19644 29.1276 9.19644 28.3264C9.19644 24.7205 12.1148 21.8346 15.7216 21.8346H23.2138C26.8214 21.8346 29.7287 24.7205 29.7287 28.3264C29.7287 29.1285 29.3781 29.8903 28.7668 30.4111C26.2614 32.5505 23.0112 33.8425 19.4626 33.8425Z" fill="#A460F6" />
      </svg>,
      title: 'New Users',
      count: '0',
      linkLabel: 'User Analytics',
      linkHref: '/dashboard/analytics/users'
    },
  ]);

  useEffect(() => {
    const getAnalyticsData = async () => {
      try {
        const from = new Date();
        from.setHours(0);
        from.setMinutes(0);
        from.setSeconds(0);
        from.setMilliseconds(0);

        const to = new Date(from.getTime());
        to.setHours(23);
        to.setMinutes(59);
        to.setSeconds(59);
        to.setMilliseconds(999);

        const data = await getDashboardAnalytics({from, to});
        const newBlocksArray = blocksArray;
        blocksArray[0].count = String(data.totalSounds);
        blocksArray[1].count = '-'; // String(data.totalCountries);
        blocksArray[2].count = String(data.totalSessions);
        blocksArray[3].count = String(data.averageHrv || '-');
        blocksArray[4].count = String(data.newUsers);

        setBlocksArray([...newBlocksArray]);
      } catch (error: any) {
        console.error('error', error);
      } finally {
        setShow(true);
      }
    };

    getAnalyticsData();

    return () => { };
  }, []);

  const currentDateFormatted = getCurrentDate();

  function getCurrentDate() {
    const daysOfWeek = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
    const monthsOfYear = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

    const currentDate = new Date();
    const dayOfWeek = daysOfWeek[currentDate.getDay()];
    const month = monthsOfYear[currentDate.getMonth()];
    const dayOfMonth = currentDate.getDate();

    return `${dayOfWeek}, ${month} ${dayOfMonth}`;
  }

  return (
    <Box sx={[{
      px: '35px',
      py: '20px',
      width: '100%',
      maxWidth: '100%',
    }]}>
      <Box sx={{
        mb: '40px'
      }}>
        <Typography variant="h1" component="h3">
          {currentDateFormatted}
        </Typography>
      </Box>
      <Box sx={{
        display: 'flex',
        flexWrap: 'wrap',
        mx: '-5px',
      }}>
        {show && blocksArray.map((block, index) => (
          <Link
            sx={{
              mx: '5px',
              mb: '10px',
              maxWidth: '200px',
              width: '100%',
              '&:hover': {
                opacity: 1,
              }
            }}
            href={block.linkHref}
            underline='none'
          >
            <Box key={index} sx={{
              border: '1px solid #E2E2E2',
              borderRadius: '4px',
              backgroundColor: '#FFFFFF',
              padding: '30px',
              paddingBottom: '20px',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'flex-start',
              width: '100%',
              boxSizing: 'border-box',
              cursor: 'pointer',
              "&:hover .MuiSvgIcon-root": {
                opacity: '1'
              }
            }}>
              <SvgIcon sx={{
                width: '40px',
                height: '40px',
                mb: '95px',
                color: '#A460F6',
                opacity: '0.5'
              }}>
                {block.icon}
              </SvgIcon>
              <Typography
                sx={{
                  fontSize: '13px',
                  fontWeight: '400',
                  color: '#333',
                  textTransform: 'uppercase',
                  mb: '10px'
                }}
                variant="h3">
                {block.title}
              </Typography>
              <Typography
                sx={{
                  fontSize: '34px',
                  fontWeight: '500',
                  color: '#422662',
                  mb: '20px'
                }}
                variant="h2">
                {block.count}
              </Typography>
              <Link
                sx={{
                  fontSize: '13px',
                  fontWeight: '400',
                  color: '#333',
                  textDecorationColor: '#333',
                }}
                href={block.linkHref}
                variant="subtitle1">
                {block.linkLabel}
              </Link>
            </Box>
          </Link>
        ))}
      </Box>
    </Box >);
};

export default HomeReal;