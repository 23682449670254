export const STORAGE_AUTH_TOKEN = 'STORAGE_AUTH_TOKEN';
export const STORAGE_USER = 'STORAGE_USER';

export const STORAGE_USER_CONFIRM_TOKEN = 'STORAGE_USER_CONFIORM_TOKEN';
export const STORAGE_USER_ROLE = 'STORAGE_USER_ROLE';
export const STORAGE_SOUND = 'STORAGE_SOUND';
export const STORAGE_SOUNDS = 'STORAGE_SOUNDS';

export const STORAGE_SHOW_REAL_DATA = 'showRealData';

export const intentionsColors = {
  graceful: '#AAE09E',
  harmony: '#73CFE9',
  peaceful: '#EEABF9',
  positive: '#EBE475',
  other: '#D9D9D9',
}