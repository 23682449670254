import {
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableSortLabel,
  TableRow,
  Tooltip,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useTheme } from '@mui/material/styles';
import { OrderBySessions, Order } from '../../types/types';
import { visuallyHidden } from '@mui/utils';
import { intentionsColors } from '../../constants/storage';

interface Data {
  id: number,
  date: string,
  time: string,
  length: 'Short' | 'Long',
  country: string,
  vibes: {
    graceful: number,
    harmony: number,
    peaceful: number,
    positive: number,
    other: number
  },
  selected_intentions: string,
  vokaturi: {
    graceful: number,
    harmony: number,
    peaceful: number,
    positive: number,
    other: number
  },
  stress: number,
  light: string,
  resilience: number,
  hrv: number
}

interface Column {
  id: 'id' | 'date' | 'time' | 'length' | 'country' | 'vibes' | 'selected_intentions' | 'vokaturi' | 'stress' | 'light' | 'resilience' | 'hrv';
  label: string;
  minWidth?: number;
  align?: 'right';
  format?: (value: number) => string;
}

const columns: readonly Column[] = [
  {
    id: 'id',
    label: 'ID',
    minWidth: 70,
  },
  {
    id: 'date',
    label: 'Date',
    minWidth: 70,
  },
  {
    id: 'time',
    label: 'Time',
    minWidth: 70,
  },
  {
    id: 'length',
    label: 'Length',
    minWidth: 90,
  },
  {
    id: 'country',
    label: 'Country',
    minWidth: 30,
  },
  {
    id: 'vibes',
    label: 'Vibes',
    minWidth: 110,
  },
  {
    id: 'selected_intentions',
    label: 'Selected Intentions',
    minWidth: 30,
  },
  {
    id: 'vokaturi',
    label: 'Vokaturi',
    minWidth: 110,
  },
  {
    id: 'stress',
    label: 'Stress',
    minWidth: 30,
  },
  {
    id: 'light',
    label: 'Light',
    minWidth: 30,
  },
  {
    id: 'resilience',
    label: 'Resilience',
    minWidth: 30,
  },
  {
    id: 'hrv',
    label: 'HRV',
    minWidth: 30,
  },
];



interface SessionsTableProps {
  data: any;
  handleRequestSort: any,
  order: Order,
  orderBy: keyof OrderBySessions,
  page: number,
}

const SessionsTableReal: React.FC<SessionsTableProps> = (
  { data, handleRequestSort, order, orderBy, page }
) => {
  const theme = useTheme();
  const [rows, setRows] = useState([]);

  useEffect(() => {
    if (data) {
      setRows(formatUserData(data));
    }
  }, [data]);

  const renderVokatury = (obj: any[]) => {

    return (
      <Box sx={{
        height: '22px',
        display: 'flex',
      }}>
        {
          obj.length > 0 ?
            obj.map((item: any) => (
              <Tooltip title={item.vibe}>
                <Box
                  sx={{
                    display: 'block',
                    height: '100%',
                    mr: '2px',
                    width: `${'20'}%`,
                    borderRadius: '2px',
                    backgroundColor: item.color,
                  }}
                  component="span"
                ></Box>
              </Tooltip>
            )) : null
        }
      </Box>
    )
  }

  const renderIntentions = (arr: any[]) => {

    return arr.map((item, id) => {
      return (
        <Box
        key={id}
        sx={{
          display: 'flex',
          alignItems: 'center',
          '&::before': {
            content: '""',
            mr: '10px',
            width: '16px',
            height: '16px',
            borderRadius: '50%',
            backgroundColor: item.color,
          }
        }}
      >{item.vibe}</Box>
      )
    })
  }

  const formatUserData = (arr: any) => {
    return arr.map((obj: any) => {
      const newObj = { ...obj };
      newObj.vokaturi = renderVokatury(obj.vokaturi);
      newObj.vibes = renderVokatury(obj.vibes);

      newObj.selected_intentions = renderIntentions(obj.selected_intentions);

      return {
        ...newObj,
      };

    });
  }

  const renderRows = (arrRows: any) => {
    return arrRows.map((row: any, rowId: any) => {
      return (
        <TableRow
          sx={{
            cursor: 'pointer',
          }}
          hover role="checkbox" tabIndex={-1} key={row.id}
        >
          {columns.map((column, id) => {
            const value = row[column.id];
            return (
              <TableCell
                sx={{
                  color: '#333333',
                  fontSize: '16px',
                  fontWeight: '400',
                  border: '1px solid #E2E2E2',
                  backgroundColor: '#FFFFFF',
                  py: '10px',
                  px: '20px',
                }}
                key={row.id + id + rowId}
                align={column.align}
              >
                {column.format && typeof value === 'number'
                  ? column.format(value)
                  : value}
              </TableCell>
            );
          })}
        </TableRow>
      );
    })
  }

  const createSortHandler =
    (property: keyof Data) => (event: React.MouseEvent<unknown>) => {
      handleRequestSort(event, property);
    };

  return (
    <div>
      <Paper sx={{
        width: '100%',
        backgroundColor: 'transparent',
        boxShadow: 'none',
      }}>
        <TableContainer>
          <Table sx={{
            backgroundColor: 'transparent',
          }}>
            <TableHead>
              <TableRow>
                {columns.map((column, id) => (
                  <TableCell
                    sx={[
                      {
                        color: '#333333',
                        fontSize: '16px',
                        fontWeight: '500',
                        py: '10px',
                        px: '20px',
                        border: '1px solid #E2E2E2',
                        textTransform: 'capitalize',
                      },
                      (theme) => ({
                        [theme.breakpoints.up('md')]: {
                          minWidth: column.minWidth
                        }
                      }),
                    ]}
                    key={column.id}
                    align={column.align}
                    sortDirection={orderBy === column.id ? order : false}
                  >
                    <TableSortLabel
                      {...(column.id === 'date' ? {onClick: createSortHandler(column.id)} : {})}
                      direction={orderBy === column.id ? order : 'asc'}
                      hideSortIcon={column.id !== 'date'}
                    >
                      {column.label}
                      {orderBy === column.id ? (
                        <Box component="span" sx={visuallyHidden}>
                          {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                        </Box>
                      ) : null}
                    </TableSortLabel>
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {renderRows(rows)}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    </div>);
};

export default SessionsTableReal;