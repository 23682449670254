import { TAnalyticsDataFilters, TAnalyticsDataResponse } from "../types/types";
import { handleApiError } from "./handleApiError";
import instance from './index';

export const getDashboardAnalytics = async (filters: TAnalyticsDataFilters): Promise<TAnalyticsDataResponse> => {
  try {
    let params = Object.entries(filters).map(([key, value]) => key + '=' + value.toISOString()).join('&');

    return await instance.get<TAnalyticsDataResponse>(`admin/analytics/dashboard${params !== '' ? '?' + params : ''}`).then(res => res.data);
  } catch (error: any) {
    handleApiError(error);
    throw error;
  }
};
